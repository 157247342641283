import { mount } from 'conce-ui';

mount({
  publicKey: 'test',
  currency: 'GBP',
  amount: 10,
  stripe: {
    publicKey: 'pk_test_GgLyjwcdEf8yO3NryV1wDJIH',
  },
  successCallback: function () {
    console.log('success');
  },
  errorCallback: function (error) {
    console.log('error', error);
  },
});
